import React, { useState, useEffect } from "react";
import { Table, Divider, message, Radio } from "antd";
import { fetchAll, remove } from "../../services/services";
import PageWrapper from "../../components/PageWrapper";
import { EditOutlined, RestOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Subject = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("TAJRIBALI");

  useEffect(() => {
    fetch();
  }, [type]);

  const fetch = () => {
    setLoading(true);
    fetchAll({ url: "/admin/subject/all", params: { type } })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    {
      title: "№",
      render: (cell, record, index) => index + 1,
      width: 60,
      align: "center",
    },
    {
      title: "Sud ixtisosligi nomi",
      dataIndex: "title",
    },
    {
      title: "Action",
      align: "right",
      render: (cell, record) => {
        return (
          <span>
            <Link to={"/admin/subject/view/" + record.id}>
              <EyeOutlined />
            </Link>
            <Divider type="vertical" />
            <Link to={"/admin/subject/edit/" + record.id}>
              <EditOutlined />
            </Link>
            <Divider type="vertical" />
            <Link
              to={""}
              onClick={(e) => {
                e.preventDefault();
                remove({ url: "/admin/subject/delete", id: record.id }).then(
                  (res) => {
                    if (res.status === 200) {
                      message.success("Subject successfully deleted!");
                      fetch();
                    } else {
                      message.error("Subject has not deleted for some reason!");
                    }
                  }
                );
              }}
            >
              <RestOutlined />
            </Link>
          </span>
        );
      },
    },
  ];

  const onTypeChange = (e) => {
    console.log(e.target.value);
    setType(e.target.value);
  };

  return (
    <PageWrapper
      title="Суд ихтисослиги"
      center={
        <Radio.Group value={type} size="middle" onChange={onTypeChange}>
          <Radio.Button value="YOSH">Yosh</Radio.Button>
          <Radio.Button value="TAJRIBALI">Tajribali</Radio.Button>
        </Radio.Group>
      }
      createLink="/admin/subject/create"
    >
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="id"
        pagination={{ pageSize: 15 }}
      />
    </PageWrapper>
  );
};

export default Subject;
