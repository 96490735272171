import React, { useEffect } from "react";
import { Form, Input, Radio } from "antd";
import { read } from "../../services/services";
import FSelect from "../../components/FSelect";
import { useParams } from "react-router-dom";

const SubjectView = () => {
  const { id } = useParams();

  const [form] = Form.useForm();
  useEffect(() => {
    read({ url: "/admin/subject/find", id }).then((res) => {
      const { data, status } = res;
      if (status === 200) {
        data.themeIds = Array.isArray(data.themeResponses)
          ? data.themeResponses.map((item) => item.id)
          : [];
        delete data.themeResponses;
        form.setFieldsValue(data);
      }
    });
  });

  return (
    <div
      style={{
        width: "30%",
        margin: "auto",
        backgroundColor: "#fff",
        padding: 24,
      }}
    >
      <h5 className="text-center mb-4">Sud ixtisosligini ko'rish</h5>
      <Form layout="vertical" name="theme-view" form={form}>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Radio.Group disabled size="middle" className="radio--group">
            <Radio.Button value="YOSH">Yosh</Radio.Button>
            <Radio.Button value="TAJRIBALI">Tajribali</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Nomi"
          name="title"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Bo'limlar"
          name="themeIds"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <FSelect
            disabled
            fetchInMount={true}
            mode="multiple"
            url="/admin/theme/all"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubjectView;
