import React from "react";
import { Form, Input, Radio, Button, message } from "antd";
import { create } from "../../services/services";
import FSelect from "../../components/FSelect";

const SubjectCreate = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Success:", values);
    create({ url: "/admin/subject/create", credentials: values }).then(
      (res) => {
        if (res.status === 200) {
          message.success("Sud ixtisosligi muvaffaqiyatli yaratildi!");
          form.resetFields();
        } else {
          message.error("Sud ixtisosligi yaratilmadi!");
        }
      }
    );
  };

  return (
    <div
      style={{
        width: "30%",
        margin: "auto",
        backgroundColor: "#fff",
        padding: 24,
      }}
    >
      <h5 className="text-center mb-4">Sud ixtisosligi yaratish</h5>
      <Form
        layout="vertical"
        name="theme-create"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="type"
          initialValue="TAJRIBALI"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Radio.Group size="middle" className="radio--group">
            <Radio.Button value="YOSH">Yosh</Radio.Button>
            <Radio.Button value="TAJRIBALI">Tajribali</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Nomi"
          name="title"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bo'limlar"
          name="themeIds"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <FSelect mode="multiple" url="/admin/theme/all" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" block htmlType="submit">
            Yaratish
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubjectCreate;
