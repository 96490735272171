import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";

const CollectionCreateForm = ({
  visible,
  onOk,
  mode,
  onCancel,
  formValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (formValues && visible) {
      setTimeout(() => {
        form.setFieldsValue(formValues);
      }, 0);
    } else {
      form.resetFields();
    }
  }, [formValues, visible]);

  return (
    <Modal
      visible={visible}
      title={
        mode === "edit"
          ? "Savolni o'zgartirish"
          : mode === "create"
          ? "Yangi savol yaratish"
          : "Savolni ko'rish"
      }
      okText={
        mode === "edit" ? "O'zgartirish" : mode === "create" ? "Yaratish" : "OK"
      }
      cancelText="Bekor qilish"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="text"
          label="Savol matni"
          rules={[
            {
              required: true,
              message: "Iltimos savolni kiriting!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CollectionCreateForm;
