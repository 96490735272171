import React, { useEffect } from "react";
import { Form, Input, Button, InputNumber, message } from "antd";
import { read, update } from "../../services/services";
import { useParams } from "react-router-dom";

const ThemeEdit = () => {
  const onFinish = (values) => {
    update({ url: "/admin/theme/update", id, credentials: values }).then(
      (res) => {
        if (res.status === 200) {
          message.success("Bo'lim muvaffaqiyatli o'zgartirildi!");
        } else {
          message.error("Bo'lim o'zgartirilmadi!");
        }
      }
    );
  };

  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
    read({ id, url: "/admin/theme/find", id }).then((res) => {
      const { data, status } = res;
      if (status === 200) {
        console.log(data);
        form.setFieldsValue(data);
      }
    });
  }, []);

  return (
    <div
      style={{
        width: "30%",
        margin: "auto",
        backgroundColor: "#fff",
        padding: "16px 24px 4px",
        borderRadius: 4,
      }}
    >
      <h5 className="text-center mb-3">Sessiyalar</h5>
      <Form
        layout="vertical"
        form={form}
        name="theme-create"
        onFinish={onFinish}
      >
        <Form.Item
          label="Nomi"
          name="title"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tartibi"
          name="order"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting",
            },
          ]}
        >
          <InputNumber className="w-100" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" block htmlType="submit">
            O'zgartirish
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ThemeEdit;
