import React, { useEffect, useState } from "react";
import { string, object, bool } from "prop-types";
import { Select, Spin, Empty } from "antd";
import { fetchAll } from "../services/services";

const FSelect = ({
  url = "",
  valueProp = "id",
  outputProp = "title",
  params = {},
  isResultArray = true,
  fetchInMount = false,
  ...rest
}) => {
  const [stateOptions, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (fetchInMount) {
      fetchAll({ url, params })
        .then((res) => {
          if (isMounted) {
            Array.isArray(res.data) && setOptions(res.data);
          }
        })
        .catch(() => {});
    }
    return () => {
      isMounted = false;
    };
  }, [fetchInMount, setOptions]);

  const handleFocus = () => {
    setLoading(true);
    setOptions([]);
    fetchAll({ url, params })
      .then((res) => {
        setLoading(false);
        Array.isArray(res.data) && setOptions(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <Select
      notFoundContent={loading ? <Spin /> : <Empty />}
      onFocus={handleFocus}
      {...rest}
    >
      {Array.isArray(stateOptions)
        ? stateOptions.map((item) => (
            <Select.Option key={item[valueProp]} value={item[valueProp]}>
              {item[outputProp]}
            </Select.Option>
          ))
        : null}
    </Select>
  );
};

FSelect.propTypes = {
  url: string.isRequired,
  params: object,
  valueProp: string,
  outputProp: string,
  fetchInMount: bool,
};

export default FSelect;
