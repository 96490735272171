import Home from "./pages/Home";
import Variants from "./pages/Variants";
import Variant from "./pages/Variant";
import Login from "./pages/Login";
import Viewers from "./pages/Viewers";
import First from "./pages/First";
import AdminHome from "./admin/AdminHome";
import Theme from "./admin/Theme/Theme";
import ThemeCreate from "./admin/Theme/ThemeCreate";
import ThemeEdit from "./admin/Theme/ThemeEdit";

import Subject from "./admin/Subject/Subject";
import SubjectEdit from "./admin/Subject/SubjectEdit";
import SubjectCreate from "./admin/Subject/SubjectCreate";
import SubjectView from "./admin/Subject/SubjectView";
import Question from "./admin/Question/Question";
import Sessions from "./admin/Session/Session";

export const publicRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/",
    component: Viewers,
  },
];

export const userRoutes = [
  {
    path: "/user/variants/:subjectId/:id",
    title: "Variant",
    component: Variant,
  },
  {
    path: "/user/variants/:subjectId",
    title: "Variants",
    component: Variants,
  },
  {
    path: "/user/:type",
    title: "Second",
    component: Home,
  },
  {
    path: "/user",
    title: "First",
    component: First,
  },
];

export const adminRoutes = [
  {
    path: "/admin/theme/edit/:id",
    title: "Theme Edit",
    component: ThemeEdit,
  },
  {
    path: "/admin/theme/create",
    title: "Theme Create",
    component: ThemeCreate,
  },
  {
    path: "/admin/theme",
    title: "Theme",
    component: Theme,
  },
  {
    path: "/admin/question",
    title: "Question",
    component: Question,
  },
  {
    path: "/admin/subject/view/:id",
    title: "Subject Edit",
    component: SubjectView,
  },
  {
    path: "/admin/subject/edit/:id",
    title: "Subject Edit",
    component: SubjectEdit,
  },
  {
    path: "/admin/subject/create",
    title: "Subject Create",
    component: SubjectCreate,
  },
  {
    path: "/admin/subject",
    title: "Subject",
    component: Subject,
  },
  {
    path: "/admin/session",
    title: "Sessiyalar",
    component: Sessions,
  },
  {
    path: "/admin",
    title: "First",
    component: AdminHome,
  },
];
