import { ACCESS_TOKEN } from "./constants";

export function blobToUrl(data) {
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(data);
}

export const setRole = (val) => {
  localStorage.setItem("role", JSON.stringify(val));
};

export function isExistRole() {
  return localStorage.getItem("role") !== null;
}

export function getRole() {
  return JSON.parse(localStorage.getItem("role"));
}

export function removeRole() {
  return localStorage.removeItem("role");
}

export function setToken(token) {
  window.localStorage.setItem(ACCESS_TOKEN, token);
}

export function getToken() {
  return window.localStorage.getItem(ACCESS_TOKEN);
}

export function rmToken() {
  window.localStorage.removeItem(ACCESS_TOKEN);
}

export const validToken = () => {
  return getToken();
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x[key]);
    return rv;
  }, {});
};

export function extractFirstCharacter(string = "") {
  return typeof string === "string" && string.length ? string[0] : "";
}

export function isJSON(data) {
  let result = true;
  try {
    JSON.parse(data);
  } catch (err) {
    result = false;
  }
  return result;
}
