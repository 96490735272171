import React, { useState, useEffect } from "react";
import { ListGroup, Row, Col, Alert, Container } from "react-bootstrap";
import { fetchAll } from "../services/services";

export default function Viewers() {
  const [data, setData] = useState({});

  useEffect(() => {
    setInterval(() => {
      fetchAll({ url: "/home" }).then((res) => {
        if (res.data.number !== data.number) {
          setData(res.data);
        }
        if (!res.data) {
          setData({});
        }
      });
    }, 2000);
  }, []);

  return (
    <Container fluid>
      <Row className="vh-100 align-items-center">
        {data.number ? (
          <Col>
            <h2 className="ml-5">{data.number}-билет</h2>
            <div>
              <h3 className="text-center py-4 mb-5">Саволлар:</h3>
              <ListGroup>
                {Array.isArray(data.questionResponses)
                  ? data.questionResponses.map((question, idx) => (
                      <ListGroup.Item key={question.id} className="border-0">
                        <div className="d-flex align-items-center">
                          <Alert variant="danger">
                            <strong className="fs-24">{idx + 1}</strong>
                          </Alert>
                          <p className="ml-2 ml-lg-4 border-bottom py-2 py-lg-3 flex-grow-1 question-item-text">
                            {question.text}
                          </p>
                        </div>
                      </ListGroup.Item>
                    ))
                  : null}
              </ListGroup>
            </div>
          </Col>
        ) : (
          <img className="img-waiting" src="/court-hammer.jpg" alt="loading" />
        )}
      </Row>
    </Container>
  );
}
