import React, { useState, useEffect } from "react";
import { Table, Divider, message } from "antd";
import { fetchAll, remove } from "../../services/services";
import PageWrapper from "../../components/PageWrapper";
import { EditOutlined, RestOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Theme = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    setLoading(true);
    fetchAll({ url: "/admin/theme/all" })
      .then((res) => {
        if (res.data) {
          setLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    {
      title: "№",
      render: (cell, record, index) => index + 1,
      width: 60,
      align: "center",
    },
    {
      title: "Bo'lim nomi",
      dataIndex: "title",
    },
    {
      title: "Action",
      align: "right",
      render: (cell, record) => {
        return (
          <span>
            <Link to={"/admin/theme/edit/" + record.id}>
              <EditOutlined />
            </Link>
            <Divider type="vertical" />
            <Link
              to={""}
              onClick={(e) => {
                e.preventDefault();
                remove({ url: "/admin/theme/delete", id: record.id }).then(
                  (res) => {
                    if (res.status === 200) {
                      message.success("Theme successfully deleted!");
                      fetch();
                    } else {
                      message.error("Theme has not deleted for some reason!");
                    }
                  }
                );
              }}
            >
              <RestOutlined />
            </Link>
          </span>
        );
      },
    },
  ];

  return (
    <PageWrapper title="Bo'limlar" createLink="/admin/theme/create">
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="id"
        pagination={{ pageSize: 15 }}
      />
    </PageWrapper>
  );
};

export default Theme;
