import React, { useState, useEffect } from "react";
import { ListGroup, Row, Col, Alert, Container, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { read } from "../services/services";

export default function Variant() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState([]);
  useEffect(() => {
    read({ url: "/user/ticket/select", id }).then((res) => {
      setData(res.data);
    });
  }, [id]);

  const finishVariant = () => {
    read({ url: "/user/ticket/complete", id }).then((res) => {
      console.log(res);

      res.data && history.push("/user");
    });
  };

  return (
    <Container className="vh-100">
      <div className="h-100 d-flex justify-content-around flex-column">
        <Row className="align-items-center">
          <Col>
            <h2 className="ml-5">{data.number}-билет</h2>
            <div>
              <h3 className="text-center py-4 mb-5 question-title">
                Саволлар:
              </h3>
              <ListGroup>
                {Array.isArray(data.questionResponses)
                  ? data.questionResponses.map((question, idx) => (
                      <ListGroup.Item key={question.id} className="border-0">
                        <div className="d-flex align-items-center">
                          <Alert variant="danger">
                            <strong className="fs-24">{idx + 1}</strong>
                          </Alert>
                          <p className="ml-2 ml-lg-4 border-bottom py-2 py-lg-3 flex-grow-1 question-item-text">
                            {question.text}
                          </p>
                        </div>
                      </ListGroup.Item>
                    ))
                  : null}
              </ListGroup>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-end mr-3">
          <Button onClick={finishVariant}>Якунлаш</Button>
        </Row>
      </div>
    </Container>
  );
}
