import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function First() {
  return (
    <Container>
      <Row className="align-items-center vh-100">
        <Col>
          <Row className="h-100 justify-content-center">
            <Col
              xl="12"
              className="d-flex align-items-center justify-content-center my-2 my-sm-4  my-lg-5"
            >
              <h2 className="text-center">Судьяликка номзод турини танланг</h2>
            </Col>
            <Col
              xl="6"
              className="d-flex align-items-center justify-content-center my-2 my-sm-4 my-lg-5 flex-grow-1"
            >
              <Card
                className={`bg-info w-75 py-2 py-sm-3 py-lg-4 position-relative`}
              >
                <Card.Body>
                  <Card.Title className="text-center">
                    <Link
                      to={`/user/yosh`}
                      className="text-decoration-none text-light test-link stretched-link"
                    >
                      Илк бор судьялик лавозимига номзод
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xl="6"
              className="d-flex align-items-center justify-content-center my-2 my-sm-4 my-lg-5 flex-grow-1"
            >
              <Card
                className={`bg-info w-75 py-2 py-sm-3 py-lg-4 position-relative`}
              >
                <Card.Body>
                  <Card.Title className="text-center">
                    <Link
                      to={`/user/tajribali`}
                      className="text-decoration-none text-light test-link stretched-link"
                    >
                      Навбатдаги муддатга судьялик лавозимига номзод
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
