import React, { useRef } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { API_BASE_URL } from "../constants";
import { setToken, setRole } from "../utils";
import { message } from "antd";

const Login = (props) => {
  const history = useHistory();

  const usernameRef = useRef();
  const passwordRef = useRef();

  const onLogin = (e) => {
    e.preventDefault();
    console.log(usernameRef.current.value);
    const credentials = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    };
    Axios.post(API_BASE_URL + "/login", credentials)
      .then((res) => {
        console.log(res);
        setToken(res.data);
        if (credentials.username === "user") {
          history.push("/user");
          setRole("user");
        } else if (credentials.username === "admin") {
          history.push("/admin");
          setRole("admin");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            message.error("Ma'lumotlar noto'g'ri kiritildi! Qaytadan urining!");
          }
        }
      });
  };
  return (
    <Container fluid className="bg-light">
      <Row className="vh-100 align-items-center justify-content-center">
        <Col xl={4} className="bg-white p-4 rounded">
          <Form>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                ref={usernameRef}
                type="text"
                placeholder="Enter username"
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                ref={passwordRef}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100"
              onClick={onLogin}
            >
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
