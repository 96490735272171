import React, { useState } from "react";
import { Layout, Menu, Button } from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import "antd/";
import { useLocation, Link, useHistory } from "react-router-dom";
import { rmToken } from "../utils";

const { Sider, Header, Content, Footer } = Layout;

export default function AppLayout({ children, layout }) {
  const location = useLocation();
  const history = useHistory();

  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKey")
      ? [localStorage.getItem("selectedKey")]
      : []
  );

  const handleSelect = ({ key }) => {
    setSelectedKey(key);
    localStorage.setItem("selectedKey", key);
  };

  const logout = () => {
    rmToken();
    history.push("/");
  };

  return !location.pathname.startsWith("/admin") ? (
    <div className="main-layout">{children}</div>
  ) : (
    <Layout className="vh-100">
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          zIndex: 99,
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKey}
          onSelect={handleSelect}
        >
          <Menu.Item key="subjects" icon={<UserOutlined />}>
            <Link to="/admin/subject">Суд ихтисослиги</Link>
          </Menu.Item>
          <Menu.Item key="themes" icon={<UploadOutlined />}>
            <Link to="/admin/theme">Бўлимлар</Link>
          </Menu.Item>
          <Menu.Item key="questions" icon={<UploadOutlined />}>
            <Link to="/admin/question">Savollar</Link>
          </Menu.Item>
          <Menu.Item key="sessions" icon={<UploadOutlined />}>
            <Link to="/admin/session">Sessiyalar</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Header
          className="site-layout-background admin-header position-fixed d-flex align-items-center justify-content-end w-100"
          style={{ padding: 0 }}
        >
          <Button className="mr-3" onClick={logout}>
            Chiqish
          </Button>
        </Header>
        <Content style={{ margin: "86px 16px 0", overflow: "initial" }}>
          {/* <div
            className="site-layout-background h-100"
            style={{ padding: 24, textAlign: "center" }}
          > */}
          {children}
          {/* </div> */}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
}
