import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

export default function PageWrapper({
  children,
  title,
  center,
  createLink,
  onCreate,
  justify,
}) {
  return (
    <div>
      <Row
        align="middle"
        className="mb-3"
        justify={createLink ? "space-between" : justify}
      >
        <Col>
          <h5>{title}</h5>
        </Col>
        {center ? (
          <Col>
            <h3>{center}</h3>
          </Col>
        ) : null}
        {createLink || onCreate ? (
          <Col>
            <Link to={createLink}>
              <Button type="primary" onClick={onCreate}>
                Yangi qo'shish
              </Button>
            </Link>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
    </div>
  );
}
