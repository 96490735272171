import React, { useState, useEffect, useRef } from "react";
import { Table, Divider, message, Button, InputNumber } from "antd";
import { read, fetchAll } from "../../services/services";
import PageWrapper from "../../components/PageWrapper";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { PAGE_SIZE } from "../../constants";
import { blobToUrl } from "../../utils";

const Sessions = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(1);

  useEffect(() => {
    fetch();
  }, [page]);

  const fetch = () => {
    setLoading(true);
    fetchAll({
      url: "/admin/ticketsession/all",
      params: { size: PAGE_SIZE, page },
    })
      .then((res) => {
        if (res.data) {
          const _result = Array.isArray(res.data.content)
            ? res.data.content.map((item, idx) => ({
                ...item,
                no: page * PAGE_SIZE + idx + 1,
              }))
            : [];
          setLoading(false);
          setData(_result);
          setTotal(res.data.totalElements);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const download = (id) => {
    read({ url: "/admin/ticketsession/download", id, responseType: "blob" })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          const _url = blobToUrl(res.data);
          window.open(_url);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeSession = (id) => {
    fetchAll({ url: "/admin/ticketsession/close", params: { id } })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          message.success("Sessiya muvaffaqiyatli yopildi!");
          fetch();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openNewSession = () => {
    if (ref) {
      console.log(ref);
      if (ref.current.currentValue && +ref.current.currentValue) {
        fetchAll({
          url: "/admin/ticketsession/open",
          params: { questionsCount: ref.current.currentValue },
        })
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              message.success("Sessiya muvaffaqiyatli ochildi!");
              ref.current.input.value = "";
              fetch();
            }
          })
          .catch((err) => {
            console.log(err);
            message.error("Oldin hozirgi sessiyani yoping!");
          });
      } else {
        message.error("Savollar miqdorini raqam bilan kirting!");
      }
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "no",
      width: 60,
      align: "center",
    },
    {
      title: "Yaratilgan sana",
      dataIndex: "generateDate",
      render: (cell) => {
        if (!cell) return null;

        const d = new Date(cell);
        const _result =
          [d.getDate(), d.getMonth() + 1, d.getFullYear()].join(".") +
          " " +
          [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
        return _result;
      },
    },
    {
      title: "Action",
      align: "right",
      render: (cell, record) => {
        return (
          <span>
            {record.open ? (
              <>
                <Link
                  to={""}
                  onClick={(e) => {
                    e.preventDefault();
                    closeSession(record.id);
                  }}
                >
                  <CloseOutlined style={{ color: "red" }} />
                </Link>
                <Divider type="vertical" />
              </>
            ) : null}
            <Link
              to={""}
              onClick={(e) => {
                e.preventDefault();
                download(record.id);
              }}
            >
              <DownloadOutlined />
            </Link>
          </span>
        );
      },
    },
  ];

  const ref = useRef(null);

  return (
    <PageWrapper
      justify="space-between"
      title="Sessiyalar"
      center={
        <div>
          <InputNumber
            ref={ref}
            style={{ width: 200, marginRight: 24 }}
            placeholder="Savol miqdorini kiriting"
          />
          <Button type="primary" onClick={openNewSession}>
            Yangi sessiya ochish
          </Button>
        </div>
      }
    >
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="id"
        onChange={({ current }) => setPage(current - 1)}
        pagination={{ pageSize: PAGE_SIZE, total }}
      />
    </PageWrapper>
  );
};

export default Sessions;
