import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { fetchAll } from "../services/services";

export default function Home() {
  const { type } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchAll({
      url: "/user/subject/all",
      params: { type: type.toUpperCase() },
    }).then((res) => {
      console.log(res);
      setData(res.data);
    });
  }, []);
  return (
    <Container>
      <Row className="align-items-center vh-100">
        <Col>
          <Row className="h-100 justify-content-center">
            <Col
              xl="12"
              className="d-flex align-items-center justify-content-center my-2 my-sm-4  my-lg-5"
            >
              <h2 className="text-center">Суд ихтисослигини танланг</h2>
            </Col>
            {data.map((speciality, idx) => (
              <Col
                key={speciality.id}
                xl="6"
                className="d-flex align-items-center justify-content-center my-2 my-sm-4 my-lg-5 flex-grow-1"
              >
                <Card
                  className={`bg-info w-75 py-2 py-sm-3 py-lg-4 position-relative`}
                >
                  <Card.Body>
                    <Card.Title className="text-center">
                      <Link
                        to={`/user/variants/${speciality.id}`}
                        className="text-decoration-none text-light test-link stretched-link"
                      >
                        {speciality.title}
                      </Link>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
