import Axios from "axios";
import { API_BASE_URL } from "../constants";
import { getToken, rmToken, setToken } from "../utils";
import history from "../history";

const request = Axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
});

request.interceptors.request.use(function (config) {
  config.headers.authorization = getToken();
  return config;
});

function requestResponseInterceptor() {
  const interceptor = request.interceptors.response.use(
    (response) => response,
    (error) => {
      networkError(error);
      if (error.response) {
        responseStatusCheck(error.response.status);
        if (error.response.status === 401) {
          request.interceptors.response.eject(interceptor);

          return request
            .get("/auth/refresh")
            .then((response) => {
              setToken(response.data.result);
              error.response.config.headers["authorization"] =
                response.data.result;
              return request(error.response.config);
            })
            .catch((error) => {
              rmToken();
              history.push("/login");
              return Promise.reject(error);
            })
            .finally(requestResponseInterceptor);
        }
      }
      return Promise.reject(error);
    }
  );
}

requestResponseInterceptor();

function networkError(err) {
  if (err.message === "Network Error" && !err.response) {
    // flash.setFlash("error", "Internal server occurred!");
    console.log("internal server error");
  }
}

function responseStatusCheck(status) {
  // if (status === 403) {
  //   history.push('/403');
  // }
  // if (status >= 500) {
  //   history.push('/500');
  // }
}

export { request };
