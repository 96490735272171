import React, { useEffect } from "react";
import { Form, Input, Radio, Button, message } from "antd";
import { read, update } from "../../services/services";
import FSelect from "../../components/FSelect";
import { useParams } from "react-router-dom";

const SubjectEdit = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
    update({ url: "/admin/subject/update", id, credentials: values }).then(
      (res) => {
        if (res.status === 200) {
          message.success("Sud ixtisosligi muvaffaqiyatli o'zgartirildi!");
        } else {
          message.error("Sud ixtisosligi o'zgartirildi!");
        }
      }
    );
  };

  const { id } = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    read({ url: "/admin/subject/find", id }).then((res) => {
      const { data, status } = res;
      if (status === 200) {
        data.themeIds = Array.isArray(data.themeResponses)
          ? data.themeResponses.map((item) => item.id)
          : [];
        delete data.themeResponses;
        form.setFieldsValue(data);
      }
    });
  });

  return (
    <div
      style={{
        width: "30%",
        margin: "auto",
        backgroundColor: "#fff",
        padding: 24,
      }}
    >
      <h5 className="text-center mb-4">Sud ixtisosligini o'zgartirish</h5>
      <Form
        layout="vertical"
        name="theme-create"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Radio.Group size="middle" className="radio--group">
            <Radio.Button value="YOSH">Yosh</Radio.Button>
            <Radio.Button value="TAJRIBALI">Tajribali</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Nomi"
          name="title"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bo'limlar"
          name="themeIds"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <FSelect fetchInMount={true} mode="multiple" url="/admin/theme/all" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" block htmlType="submit">
            O'zgartirish
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubjectEdit;
