import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { read } from "../services/services";

export default function Variants() {
  const { subjectId } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    read({ url: "/user/ticket/find", id: subjectId }).then((res) => {
      console.log(res);
      setData(res.data);
    });
  }, [subjectId]);
  return (
    <Container>
      <Row className="vh-100 align-items-center">
        <Col>
          <h2 className="text-center my-2 my-lg-4 pb-2 pb-lg-5">
            Билет рақамини танланг
          </h2>
          <div className="d-flex flex-wrap justify-content-center">
            {data.map((variant, idx) => (
              <div
                key={idx}
                className={
                  "variant-item m-2 m-lg-3 d-flex align-items-center justify-content-center position-relative bg-info"
                }
              >
                <Link
                  to={`/user/variants/${subjectId}/${variant.id}`}
                  className="stretched-link text-light test-link text-decoration-none"
                >
                  {variant.number}
                </Link>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
