import { request } from "./request";

export const fetchAll = async ({ url = "", params = {} }) => {
  return request.get(url, { params });
};

export const read = async ({ url = "", id = "", params = {}, ...rest }) => {
  return request.get(`${url}/${id}`, { params, ...rest });
};

export const create = async ({ url = "", credentials = {}, params = {} }) => {
  return request.post(url, credentials, { params });
};

export const update = async ({
  url = "",
  id = "",
  credentials = {},
  params = {},
}) => {
  return request.put(`${url}/${id}`, credentials, { params });
};

export const remove = async ({ url = "", id = "" }) => {
  return request.delete(`${url}/${id}`);
};
