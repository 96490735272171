import React from "react";
import { Form, Input, Button, InputNumber, message } from "antd";
import { create } from "../../services/services";

const ThemeCreate = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    create({ url: "/admin/theme/create", credentials: values }).then((res) => {
      if (res.status === 200) {
        message.success("Bo'lim muvaffaqiyatli yaratildi!");
        form.resetFields();
      } else {
        message.error("Bo'lim yaratilmadi!");
      }
    });
  };

  return (
    <div
      style={{
        width: "30%",
        margin: "auto",
        backgroundColor: "#fff",
        padding: "16px 24px 4px",
        borderRadius: 4,
      }}
    >
      <h5 className="text-center mb-3">Bo'lim yaratish</h5>
      <Form
        layout="vertical"
        name="theme-create"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Nomi"
          name="title"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tartibi"
          name="order"
          rules={[
            {
              required: true,
              message: "Iltimos qiymat kiriting",
            },
          ]}
        >
          <InputNumber className="w-100" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" block htmlType="submit">
            Yaratish
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ThemeCreate;
