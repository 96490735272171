import React, { useState, useEffect } from "react";
import { Table, Divider, message } from "antd";
import { remove, read, create, update } from "../../services/services";
import PageWrapper from "../../components/PageWrapper";
import { EditOutlined, RestOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { PAGE_SIZE } from "../../constants";
import CollectionCreateForm from "./QuestionForm";
import FSelect from "../../components/FSelect";

const Question = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [themeId, setThemeId] = useState("");
  const [page, setPage] = useState(0);

  const [visible, setVisible] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [mode, setMode] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [total, setTotal] = useState(1);

  const onOk = (values) => {
    if (mode === "edit" && formValues) {
      update({
        url: "/admin/question/update",
        id: currentId,
        credentials: { ...values, themeId },
      }).then((res) => {
        if (res.status === 200) {
          fetch();
          setVisible(false);
        }
      });
    } else if (mode === "create") {
      create({
        url: "/admin/question/create",
        credentials: { ...values, themeId },
      }).then((res) => {
        if (res.status === 200) {
          fetch();
          setVisible(false);
        }
      });
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [themeId, page]);

  const fetch = () => {
    setLoading(true);
    read({
      url: "/admin/question/all",
      id: themeId,
      params: { size: PAGE_SIZE, page },
    })
      .then((res) => {
        if (res.data) {
          const _result = Array.isArray(res.data.content)
            ? res.data.content.map((item, idx) => ({
                ...item,
                no: page * PAGE_SIZE + idx + 1,
              }))
            : [];

          setLoading(false);
          setTotal(res.data.totalElements);
          setData(_result);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "no",
      width: 60,
      align: "center",
    },
    {
      title: "Savol matni",
      dataIndex: "text",
    },
    {
      title: "Action",
      align: "right",
      render: (cell, record) => {
        return (
          <span>
            <Link
              to={""}
              onClick={(e) => {
                e.preventDefault();
                setVisible(true);
                setFormValues(record);
                setMode("view");
              }}
            >
              <EyeOutlined />
            </Link>
            <Divider type="vertical" />
            <Link
              to={""}
              onClick={(e) => {
                e.preventDefault();
                setCurrentId(record.id);
                setVisible(true);
                console.log(record);
                setFormValues(record);
                setMode("edit");
              }}
            >
              <EditOutlined />
            </Link>
            <Divider type="vertical" />
            <Link
              to={""}
              onClick={(e) => {
                e.preventDefault();
                remove({ url: "/admin/question/delete", id: record.id }).then(
                  (res) => {
                    if (res.status === 200) {
                      message.success("Question successfully deleted!");
                      fetch();
                    } else {
                      message.error(
                        "Question has not deleted for some reason!"
                      );
                    }
                  }
                );
              }}
            >
              <RestOutlined />
            </Link>
          </span>
        );
      },
    },
  ];

  return (
    <PageWrapper
      title="Savollar"
      createLink="#"
      center={
        <FSelect
          placeholder="Bo'limni tanlang..."
          style={{ width: 300 }}
          url="/admin/theme/all"
          onSelect={(key) => setThemeId(key)}
        />
      }
      onCreate={
        themeId
          ? () => {
              setMode("create");
              setVisible(true);
            }
          : () => message.info("Avvar bo'limni tanlang")
      }
    >
      <CollectionCreateForm
        visible={visible}
        onOk={onOk}
        mode={mode}
        formValues={formValues}
        onCancel={() => {
          setVisible(false);
          setMode("create");
        }}
      />

      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="id"
        onChange={({ current }) => setPage(current - 1)}
        pagination={{ pageSize: PAGE_SIZE, total }}
      />
    </PageWrapper>
  );
};

export default Question;
