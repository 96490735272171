import React, { useEffect } from "react";
import "./App.scss";
import history from "./history";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "./layouts";
import { publicRoutes, adminRoutes, userRoutes } from "./routes";
import { rmToken, validToken, getRole, removeRole } from "./utils";

function App() {
  console.log(history);

  useEffect(() => {
    if (history.location.pathname === "/") {
      rmToken();
    }
    if (
      (history.location.pathname.startsWith("/user") &&
        getRole() === "admin") ||
      (history.location.pathname.startsWith("/admin") && getRole() === "user")
    ) {
      rmToken();
      removeRole();
      window.location.pathname = "/login";
    }
  }, [history.location.pathname]);

  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Switch>
            {publicRoutes.map(({ path, component: Component }, idx) => (
              <Route key={idx} path={path} exact>
                <Component />
              </Route>
            ))}
            {userRoutes.map(({ path, component: Component }, idx) => (
              <Route
                exact
                key={idx}
                path={path}
                render={(props) =>
                  validToken() ? <Component /> : <Redirect to="/login" />
                }
              />
            ))}

            {adminRoutes.map(({ path, component: Component }, idx) => (
              <Route
                exact
                key={idx}
                path={path}
                render={(props) =>
                  validToken() ? <Component /> : <Redirect to="/login" />
                }
              />
            ))}
          </Switch>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
